@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Righteous&display=swap");

.Heads {
  text-transform: uppercase;
  color: #56a06c;
  text-align: center;
}

.Title {
  font-family: "Dancing Script", cursive;
}

.Others {
  font-family: "Righteous", cursive;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dropdown-toggle::after {
  display: none !important;
}

.Heads {
  text-transform: uppercase;
  color: #56a06c;
  text-align: center;
}

.Title {
  font-family: "Dancing Script", cursive;
}

.Others {
  font-family: "Righteous", cursive;
}

.image {
  inline-size: 80%;
  block-size: 80vh;
}
